<script>
export default {
  name: "News"
}
</script>

<template>
  <div class="box-v2 dash-content news">
    <h3 class="box-title"><i class="fa-duotone fa-newspaper m-r-xs" /> Quadro de avisos </h3>
    <ul>
      <li>
        <span class="news-dt">15 de abril</span>
        <div>
          <a href="https://docs.suporteleiloes.com/blog/erp-v3.6" target="_blank">A versão 3.6.0 foi lançada! Confira aqui as novidades</a>
        </div>
      </li>
      <!--<li>
        <span class="news-dt">15 de abril</span>
        <div>
          <a href="https://docs.suporteleiloes.com/blog/validacao-biometria-facial-selfie" target="_blank">Aprenda como implementar a validação do cadastro por Selfie e Biometria Facial</a>
        </div>
      </li>-->
    </ul>
  </div>
</template>
