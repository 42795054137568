<template>
  <div class="tarefas-dashboard">
    <h3>Acompanhe o andamento dos três chamados em aberto mais próximos de serem atendidos</h3>
    <div class="slc-list" v-if="!loading">
      <div v-for="(task, key) in tasks" :key="task.id" class="list-item" :class="{first: key === 0}">
        <div class="item-count">
          <span>#{{ key+1 }}</span>
        </div>
        <div class="item-desc">
          <task-group-persons v-if="task.cache && task.cache.participants" :persons="task.cache.participants" no-click />
          <div class="task-description">{{ task.description }}</div>
          <div class="task-prazo" v-if="task.deadline">
            <div v-if="isToday(task.deadline)"><span class="dl-dt">Hoje</span></div>
            <div v-else><span class="dl-dt">{{ task.deadline.date|formatDateDistance }}</span></div>
            <div class="prev-alert">Previsto</div>
          </div>
          <div class="task-prazo" v-else>
            <div>Sem previsão</div>
          </div>
          <div class="task-status" v-if="isEmAndamento(task)">
            <i class="fa-duotone fa-spin fa-spinner" /> <span>Em andamento</span>
          </div>
          <div class="task-status text-purple" v-else-if="isHomolog(task)">
            <i class="fa-duotone fa-user-check" /> <span>Homologar</span>
          </div>
          <div class="task-status" v-else>
            <i class="fa-duotone fa-hourglass-start" /> <span>Na fila</span>
          </div>
          <div class="task-opts">
            <u-btn @click="$router.push({name: 'suporte.cliente.tarefa', params: {id: task.id}})" size="sm" no-caps color="green" class="sl-cli-btn">Acessar</u-btn>
          </div>
        </div>
      </div>
      <div v-if="!tasks || !tasks.length" class="full-width flex items-center column justify-center" style="min-height: 200px">
        <div>Nenhuma solicitação ativa até o momento.</div>
        <div>
          <u-btn @click="$emit('new')" class="sl-btn m-t" color="green" no-caps label="Abrir um novo caso"></u-btn>
        </div>
      </div>

<!--      <div class="list-item" v-for="i in 2">
        <div class="item-count">
          <span>#{{ i+1 }}</span>
        </div>
        <div class="item-desc">
&lt;!&ndash;          <div class="task-group-persons">
          </div>&ndash;&gt;
          <div class="task-description no-person">Sequência de foto para Aplicativo de Vistoria</div>
          <div class="task-prazo">
            <div>Amanhã</div>
            <div class="prev-alert">Previsto</div>
          </div>
          <div class="task-status">
            <i class="fa-duotone fa-hourglass-start" /> <span>Na fila</span>
          </div>
          <div class="task-opts">
            <u-btn size="sm" no-caps color="green" class="sl-cli-btn">Acessar</u-btn>
          </div>
        </div>
      </div>-->
    </div>
    <div class="slc-list is-loading" v-else>
      <sl-loading loading-style="coffe" />
    </div>
    <div class="footer">
      <u-btn @click="$router.push({name: 'suporte.cliente.tarefas'})" size="md" label="Acessar todos os chamados" class="sl-cli-btn" no-caps color="blue-grey" /> <u-btn @click="load" icon="refresh" size="xs" color="blue-grey-4" flat round icon-type="fa" />
    </div>
  </div>
</template>

<script>
import {list} from "@/domain/tarefas/services"
import SlLoading from "components/layout/components/Loading.vue"
import TaskGroupPersons from "components/sltarefas/components/others/TaskGroupPersons.vue"
import {isToday, parseISO} from "date-fns";
import {ptBR} from "date-fns/locale";

export default {
  name: "SuporteClienteTarefasDashboard",
  components: {
    TaskGroupPersons,
    SlLoading
  },
  data () {
    return {
      loading: true,
      tasks: []
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      const extraFilters = []
      extraFilters.push('internalStatus=opened')
      extraFilters.push('needGerenciaData=1')
      let filtros = encodeURI(`&sortBy=queue&descending=false&${extraFilters.join('&')}`)

      list(3, 1, filtros, false, true)
          .then(({data}) => {
            this.tasks = data.result
            if (data.clientStats) {
              this.$emit('clientStats', data.clientStats)
            }
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    isEmAndamento (task) {
      return task && task.status && task.status.code === 'in-progress'
    },
    isOpened (task) {
      return task && task.status && task.status.code === 'open'
    },
    isHomolog (task) {
      return task && task.status && task.status.code === 'homolog'
    },
    isToday(d) {
      return isToday(parseISO(d.date || d))
    }
  }
}
</script>
