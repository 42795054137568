<template>
  <div class="tarefas-dashboard">
    <p class="release-title"><i class="fa-duotone fa-code-branch fa-fw" /> Release</p>
    <h3>Acompanhe o que está <strong>sendo desenvolvido para novas versões</strong> do software e pesquisas tecnológicas</h3>
    <task-list-page mode="cliente" class="dash-tasks" ref="list" :autoload="true" :extra-filters="['public=1&descending=false']" is-gerencia />
    <div class="footer">
      <!-- @TODO: Descomentar após implementar o release no docs -->
<!--      <a href="https://docs.suporteleiloes.com/release/" type="a" target="_blank">
        <u-btn size="md" label="Visualizar todos as melhorias previstas" class="sl-cli-btn" no-caps color="blue-grey" />
      </a>-->
    </div>
  </div>
</template>

<script>
import TaskListPage from "components/suporte/components/task/ListPage.vue"

export default {
  name: "SuporteClienteReleaseDashboard",
  components: {TaskListPage}
}
</script>
